import dashboard from './dashboard';
import userManagement from './userManagement';
import { NavItemType } from 'types';
import settings from './settings';
import promoCodesManagemant from './promoCode';

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, userManagement, promoCodesManagemant, settings]
};

export default menuItems;
