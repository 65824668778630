import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CHANGE_PASSWORD, GET_USER_PROFILE, UPDATE_ADMIN, UPLOAD_PROFILE_IMAGE, GET_AUTH_URL, VERIFY_OTP, AUTH_OTP_DISABLE } from 'grapqhl';
import {
    Admin,
    AdminResponse,
    ChangePasswordDTO,
    ChangePasswordResponse,
    OTPAuthUrlResponse,
    PreSignedUrlResponse,
    UserProfileResponse,
    VerifyOTPAuthUrlResponse,
    uploadProfileImageDTO
} from '../../../types/profile';

export const useGQL = () => {
    const ADMIN_CHANGE_PASSWORD = () => useMutation<ChangePasswordDTO, ChangePasswordResponse>(CHANGE_PASSWORD);
    const GET_ADMIN_PROFILE = () => useLazyQuery<UserProfileResponse>(GET_USER_PROFILE);
    const UPDATE_ADMIN_PROFILE = () => useMutation<AdminResponse, { id: string; input: Partial<Admin> }>(UPDATE_ADMIN);
    const IMAGE_UPLOAD = () => useMutation<PreSignedUrlResponse, { input: uploadProfileImageDTO }>(UPLOAD_PROFILE_IMAGE);
    const GET_OTP_AUTH_URL = (skipCondition) => useQuery<OTPAuthUrlResponse>(GET_AUTH_URL, { skip: skipCondition });
    const VERIFY_AUTH_OTP = () => useMutation<VerifyOTPAuthUrlResponse, { token: string }>(VERIFY_OTP);
    const DISABLE_AUTH_OTP = () => useMutation<any, { token: string }>(AUTH_OTP_DISABLE);

    return {
        ADMIN_CHANGE_PASSWORD,
        GET_ADMIN_PROFILE,
        UPDATE_ADMIN_PROFILE,
        IMAGE_UPLOAD,
        GET_OTP_AUTH_URL,
        VERIFY_AUTH_OTP,
        DISABLE_AUTH_OTP
    };
};
