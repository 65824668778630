import { gql } from '@apollo/client';

export const GET_ALL_APP_USERS = gql`
    query ($input: GetAppUsersDTO!) {
        getAllAppUsers(input: $input) {
            message
            users {
                _id
                authProvider
                authProviderId
                firstName
                lastName
                address {
                    displayAddress
                    location {
                        type
                        coordinates
                    }
                }
                bio
                status
                profileSetupIndex
                lastLoggedInAt
                createdAt
                updatedAt
                contacts {
                    phone {
                        type
                        dialCode
                        number
                        isVerified
                    }
                    email {
                        email
                        isVerified
                    }
                }
                userType
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_APP_USER = gql`
    query GetAppUser($id: String!) {
        getAppUser(id: $id) {
            message
            pagination {
                total
                hasNextPage
            }
            user {
                _id
                authProvider
                authProviderId
                password
                firstName
                lastName
                address {
                    displayAddress
                    location {
                        type
                        coordinates
                    }
                }
                status
                bio
                lastLoggedInAt
                createdAt
                updatedAt
                contacts {
                    phone {
                        type
                        dialCode
                        number
                        isVerified
                    }
                    email {
                        email
                        isVerified
                    }
                }
                userType
                gender
                sexuality
                preferredGender
                preferredSexuality
                interests
                lookingFor
                interestsTitle
                lookingForTitle
                birthDate
            }
        }
    }
`;

export const GET_SUBSCRIPTION_TRANSACTIONS = gql`
    query GetTranscation($input: BasePaginationParams!) {
        getTransaction(input: $input) {
            message
            data {
                userId
                transactionId
                price
                subscriptionFrequency
                subscriptionStatus
                userType
                planType
                spot
                createdAt
                displayName
                discount
                purchaseDate
                expirationDate
                price_in_purchased_currency
                currency
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_LIKE_SENT_TO = gql`
    query GetAllLikeSent($input: GetLikesDTO!) {
        getAllLikeSent(input: $input) {
            message
            likeSent {
                _id
                createdAt
                userProfile {
                    _id
                    contacts {
                        phone {
                            number
                        }
                        email {
                            email
                        }
                    }
                    firstName
                    profileImage
                    status
                }
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_LIKE_RECEIVE_FROM = gql`
    query LikeReceive($input: GetLikesDTO!) {
        getAllLikeReceive(input: $input) {
            likeReceive {
                _id
                createdAt
                userProfile {
                    _id
                    contacts {
                        phone {
                            number
                        }
                        email {
                            email
                        }
                    }
                    firstName
                    profileImage
                    status
                }
            }
            message
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ALL_EXCLUSIVE_USERS = gql`
    query GetAllExclusiveUsers($input: GetExclusiveUsersDTO!) {
        getAllExclusiveUsers(input: $input) {
            message
            exclusiveUsers {
                firstName
                phone
                createdAt
                email
                _id
                exclusiveUserId
                userId
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_MEDIA = gql`
    query GetMedia($input: UserIdInputDto!) {
        getMedia(input: $input) {
            list {
                isVideo
                key
                url
                ext
                contentType
            }
        }
    }
`;

export const GET_ALL_MATCH_USERS = gql`
    query GetAllMatchUsers($input: GetMatchUsersDTO!) {
        getAllMatchUsers(input: $input) {
            message
            matchUsers {
                firstName
                phone
                createdAt
                email
                _id
                matchee
                userId
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_DASHBOARD_DETAILS = gql`
    query GetDashboardDetail($input: GetDashboardDetailInput!) {
        getDashboardDetail(input: $input) {
            allUsers
            newUsers
            newPurchases
            userDetail {
                monthly
                everyThreeMonth
                everySixMonth
                total
                totalRevenue
            }
            purchases {
                honey
                plusFiveHoney
                plusTenHoney
                pot
                plusFivePot
                plusTenPot
                total
                totalRevenue
            }
            totalRevenue {
                subscriptions
                purchases
                total
            }
            allTotalRevenue
        }
    }
`;

export const ALL_INTEREST = gql`
    query GetAllInterest {
        getAllInterest {
            interests {
                title
                slug
            }
        }
    }
`;

export const ALL_LOOKINGFOR = gql`
    query LookingFor {
        getAllLookingFor {
            lookingFor {
                title
                slug
            }
        }
    }
`;

export const USERTYPE_DATA = gql`
    query GetUserTypes {
        getUserTypes {
            key
            value
        }
    }
`;

export const GENDER_DATA = gql`
    query GetGender {
        getGender {
            key
            value
        }
    }
`;

export const PREFERRED_GENDER_DATA = gql`
    query GetPreferredGender {
        getPreferredGender {
            key
            value
        }
    }
`;

export const SEXUALITY_DATA = gql`
    query GetSexuality {
        getSexuality {
            key
            value
        }
    }
`;

export const PREFERRED_SEXUALITY_DATA = gql`
    query GetPreferredSexuality {
        getPreferredSexuality {
            key
            value
        }
    }
`;
