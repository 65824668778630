import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import SubscriptionProductsList from 'views/subscriptionProducts';
import AddSubscriptionProduct from 'views/subscriptionProducts/forms/addProduct';
import EditSubscriptionProduct from 'views/subscriptionProducts/forms/editProduct';
import EditPage from 'views/pageManagement/forms/editPage';
import ListPromoCodes from 'views/promocodeManagement';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/default')));

// menu routing
const MenuList = Loadable(lazy(() => import('views/menu/menusList')));
const AddMenu = Loadable(lazy(() => import('views/menu/forms/addMenu')));
const EditMenu = Loadable(lazy(() => import('views/menu/forms/editMenu')));

// page management routing
const PageList = Loadable(lazy(() => import('views/pageManagement')));

// menu routing
const TaxonomyList = Loadable(lazy(() => import('views/taxonomy/taxonomyList')));
const ViewTaxonomy = Loadable(lazy(() => import('views/taxonomy/taxonsList')));
const AddTaxon = Loadable(lazy(() => import('views/taxonomy/forms/addTaxonForm')));
const EditTaxon = Loadable(lazy(() => import('views/taxonomy/forms/editTaxonForm')));

// User managemenet routing
const AdminList = Loadable(lazy(() => import('views/userManagement/admins')));
const AdminView = Loadable(lazy(() => import('views/userManagement/admins/forms/view-admin')));
const AdminAdd = Loadable(lazy(() => import('views/userManagement/admins/forms/add-admin')));
const AdminEdit = Loadable(lazy(() => import('views/userManagement/admins/forms/edit-admin')));
const AdminProfile = Loadable(lazy(() => import('views/profile')));
const AdminAccountProfile = Loadable(lazy(() => import('views/profile/user-profile')));
const AdminChangePassword = Loadable(lazy(() => import('views/profile/change-password')));
const UserList = Loadable(lazy(() => import('views/userManagement/users')));
const UserProfile = Loadable(lazy(() => import('views/userManagement/users/profile')));
const AppUserList = Loadable(lazy(() => import('views/userManagement/appUsers')));
const AppUserProfile = Loadable(lazy(() => import('views/userManagement/appUsers/profile')));
const AddAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/AddAppUser')));
const EditAppUser = Loadable(lazy(() => import('views/userManagement/appUsers/forms/EditAppUser')));

/* faq */
const FAQList = Loadable(lazy(() => import('views/FAQ')));
const AddFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/addForm')));
const EditFAQV1 = Loadable(lazy(() => import('views/FAQ/forms/editForm')));
const ManageFAQ = Loadable(lazy(() => import('views/FAQ/tables')));

/* testimonials */
const TestimonialsList = Loadable(lazy(() => import('views/testimonials')));
const AddTestimonials = Loadable(lazy(() => import('views/testimonials/forms/AddTestimonials')));
const EditTestimonials = Loadable(lazy(() => import('views/testimonials/forms/EditTestimonials')));

/* email template */
const EmailTemplateList = Loadable(lazy(() => import('views/emailTemplate')));
const AddEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/addTemplate')));
const EditEmailTemplate = Loadable(lazy(() => import('views/emailTemplate/forms/editTemplate')));

// Mock component
const MockComponent = Loadable(lazy(() => import('views/mock')));

// Error routing
// const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/error')));

/* settings */
const SettingsList = Loadable(lazy(() => import('views/settings/settingsList')));

const ReportUserList = Loadable(lazy(() => import('views/reports')));
const ReportFormDetails = Loadable(lazy(() => import('views/reports/forms/report-details')));

/* promo codes */
const PromoCodesList = Loadable(lazy(() => import('views/promocodeManagement')));
const AddEditPromoCode = Loadable(lazy(() => import('views/promocodeManagement/forms/AddEditPromoCode')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'dashboard',
            element: <Dashboard />
        },
        {
            path: 'default',
            element: <DashboardDefault />
        },
        {
            path: 'menu/list',
            element: <MenuList />,
            allowedRoles: ['superAdmin', 'admin', 'editor']
        },
        {
            path: 'menu/add',
            element: <AddMenu />
        },
        {
            path: 'menu/edit/:id',
            element: <EditMenu />
        },
        {
            path: 'taxonomy/list',
            element: <TaxonomyList />
        },
        {
            path: 'taxonomy/view/:taxonomyId',
            element: <ViewTaxonomy />
        },
        {
            path: 'taxon/add/:taxonomyId',
            element: <AddTaxon />
        },
        {
            path: 'taxon/edit/:taxonomyId/:id',
            element: <EditTaxon />
        },

        {
            path: 'page-management/edit/:id',
            element: <EditPage />
        },

        {
            path: 'page-management/list',
            element: <PageList />
        },
        { path: 'promo-code/list', element: <PromoCodesList /> },
        { path: 'promo-code/add', element: <AddEditPromoCode /> },
        { path: 'promo-code/edit/:id', element: <AddEditPromoCode /> },
        {
            path: 'user/list',
            element: <UserList />
        },
        {
            path: 'user/profile/:id',
            element: <UserProfile />
        },
        {
            path: 'app-user/list',
            element: <AppUserList />,
            allowedRoles: ['superAdmin', 'admin', 'editor']
        },
        {
            path: 'app-user/add',
            element: <AddAppUser />
        },
        {
            path: 'app-user/edit/:id',
            element: <EditAppUser />
        },
        {
            path: 'app-user/profile/:id/:tab?',
            element: <AppUserProfile />
        },
        {
            path: 'admin/list',
            element: <AdminList />
        },
        {
            path: 'admin/view/:id',
            element: <AdminView />
        },
        {
            path: 'admin/add',
            element: <AdminAdd />
        },
        {
            path: 'admin/edit/:id',
            element: <AdminEdit />
        },
        {
            path: 'admin/profile',
            element: <AdminProfile />
        },
        {
            path: 'admin/account-profile',
            element: <AdminAccountProfile />
        },
        {
            path: 'admin/change-password',
            element: <AdminChangePassword />
        },
        {
            path: 'faq/list',
            element: <FAQList />
        },
        {
            path: 'faq/add',
            element: <AddFAQV1 />
        },
        {
            path: 'faq/edit/:id',
            element: <EditFAQV1 />
        },
        {
            path: 'faq/manage-faq',
            element: <ManageFAQ />
        },
        {
            path: 'email-template/list',
            element: <EmailTemplateList />
        },
        {
            path: 'email-template/add',
            element: <AddEmailTemplate />
        },
        {
            path: 'email-template/edit/:id',
            element: <EditEmailTemplate />
        },
        {
            path: '/testimonials/list',
            element: <TestimonialsList />
        },
        {
            path: 'testimonials/add',
            element: <AddTestimonials />
        },
        {
            path: 'testimonials/edit/:id',
            element: <EditTestimonials />
        },
        {
            path: 'subscription-products/list',
            element: <SubscriptionProductsList />
        },
        {
            path: 'subscription-products/add',
            element: <AddSubscriptionProduct />
        },
        {
            path: 'subscription-products/edit/:id',
            element: <EditSubscriptionProduct />
        },
        {
            path: 'mock',
            element: <MockComponent />
        },
        {
            path: 'settings/edit',
            element: <SettingsList />
        },
        {
            path: 'reports/list',
            element: <ReportUserList />
        },
        {
            path: 'reports/view/:id',
            element: <ReportFormDetails />
        }
    ]
};

export default MainRoutes;
