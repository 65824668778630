/* eslint-disable prettier/prettier */
import { gql } from '@apollo/client';

export const CREATE_APP_USER = gql`
    mutation ($input: CreateAppUserDTO!) {
        createAppUser(input: $input) {
            message
            user {
                _id
                authProvider
                authProviderId
                firstName
                lastName
                address {
                    displayAddress
                    location {
                        type
                        coordinates
                    }
                }
                bio
                status
                lastLoggedInAt
                createdAt
                updatedAt
            }
        }
    }
`;

export const UPDATE_APP_USER = gql`
mutation UpdateAppUser($id: String!, $input: UpdateAppUserDTO!) {
    updateAppUser(id: $id, input: $input) {
      message
      pagination {
        total
        hasNextPage
      }
      user {
        _id
        authProvider
        authProviderId
        password
        firstName
        lastName
        address {
          displayAddress
          location {
            type
            coordinates
          }
        }
        status
        bio
        lastLoggedInAt
        createdAt
        updatedAt
        contacts {
          phone {
            type
            dialCode
            number
            isVerified
          }
          email {
            email
            isVerified
          }
        }
        userType
      }
    }
  }
`;

export const DELETE_APP_USER = gql`
    mutation ($id: String!) {
        deleteAppUser(id: $id) {
            message
        }
    }
`;

export const APP_USER_CHANGE_PASSWORD = gql`
    mutation ($input: AppUserChangePasswordDTO!) {
        appUserchangePassword(input: $input) {
            message
        }
    }
`;

export const APP_USER_RESET_PASSWORD = gql`
    mutation ($input: AppUserResetPasswordDTO!) {
        appUserResetPassword(input: $input)
    }
`;

export const SEND_RESET_PASSWORD_MAIL = gql`
    mutation ($email: String!, $name: String!, $userId: String!) {
        sendPasswordResetMail(email: $email, name: $name, userId: $userId)
    }
`;

export const DISABLE_APP_USER = gql`
    mutation DisableAppUser($body: DisableAppUserInput!) {
        disableAppUser(Body: $body) {
            message
        }
    }
`;

export const ENABLE_APP_USER = gql`
    mutation EnableAppUser($body: DisableAppUserInput!) {
        enableAppUser(Body: $body) {
            message
        }
    }
`;

export const UPDATE_APP_USER_STATUS = gql`
    mutation UpdateAppUserStatus($updateAppUserStatusId: String!, $input: UpdateAppUserStatusDTO!) {
        updateAppUserStatus(id: $updateAppUserStatusId, input: $input) {
            message
        }
    }
`;

export const GET_MEDIA_UPLOAD_URL = gql`
    mutation GetUploadUrl($input: GetUploadUrlDto!) {
        getUploadUrl(input: $input) {
            message
            list {
                key
                isVideo
                url
                ext
                imageThumbs
                videoThumb
                contentType
            }
        }
    }
`;
export const SAVE_MEDIA = gql`
    mutation SaveMedia($input: MediaInputDto!) {
        saveMedia(input: $input) {
            message
            list {
                key
                isVideo
                url
                ext
                imageThumbs
                videoThumb
                contentType
            }
        }
    }
`;

export const DELETE_MEDIA = gql`
    mutation DeleteMedia($input: MediaSingleInputDto!) {
        deleteMedia(input: $input) {
            message
        }
    }
`;
