import spinner from '../../assets/images/spinner.gif';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
const Spinner = () => (
    <SpinnerWrapper>
        <CircularProgress />
    </SpinnerWrapper>
);

export default Spinner;

const SpinnerWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #ffffffa3;
        z-index: -1;
    }

    svg {
        circle {
            stroke: #cd7f32;
        }
    }
`;
