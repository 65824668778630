import { gql } from '@apollo/client';

export const UPDATE_ADMIN = gql`
    mutation UpdateAdmin($id: String!, $input: UpdateAdminDTO!) {
        updateAdmin(id: $id, input: $input) {
            message
            admin {
                _id
                firstName
                lastName
                email
                phone
                birthDate
                role
                status
            }
        }
    }
`;

export const DELETE_ADMIN = gql`
    mutation ($id: String!) {
        removeAdmin(id: $id) {
            message
            admin {
                _id
            }
        }
    }
`;
