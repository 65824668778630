import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Typography
} from '@mui/material';

// third-party

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

// assets
import { IconLogout, IconSettings } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import { useDispatch } from 'react-redux';
import { setLoginState } from 'store/slices/auth';
import { useGQL } from 'views/profile/hooks/useGQL';

// ==============================|| PROFILE MENU ||============================== //

export enum AdminRoles {
    SUPERADMIN = 'SUPERADMIN',
    ADMIN = 'ADMIN',
    EDITOR = 'EDITOR'
}

const ProfileSection = () => {
    const accessToken = localStorage.getItem('accessToken');
    const theme = useTheme();
    const dispatch = useDispatch();
    const { borderRadius } = useConfig();
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);

    const [profileUrl, setProfileUrl] = useState<any>('');
    const { GET_ADMIN_PROFILE } = useGQL();
    const [refetch, { error, loading, data }] = GET_ADMIN_PROFILE();

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    useEffect(() => {
        if (data?.getUserProfile) {
            let { profileImage, profileImageUrl } = data.getUserProfile;

            if (profileImage) {
                setProfileUrl(profileImageUrl);
            }
        }
    }, [loading, data]);

    useEffect(() => {
        refetch();
    }, [accessToken]);

    const handleLogout = async () => {
        /* cleanup auth state */
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('isBrowserVerified');

        dispatch(setLoginState({ isLoggedIn: false, isBrowserVerified: false }));
        navigate('/login', { replace: true });
    };

    const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number, route: string = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = useRef(open);

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '46px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.mainFade,

                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.mainFade,
                        background: `${theme.palette.primary.mainFade}!important`,
                        color: theme.palette.primary.light,
                        '& svg': {
                            // stroke: theme.palette.primary.light
                            stroke: theme.palette.text.grey2
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0,
                        paddingRight: '4px'
                    }
                }}
                icon={
                    <Avatar
                        src={profileUrl}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '7px 0 7px 4px !important',
                            cursor: 'pointer',
                            width: '32px',
                            height: '32px'
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                    />
                }
                label={
                    <IconSettings
                        stroke={1.5}
                        size="1rem"
                        style={{ width: '32px', height: '32px', padding: '6px', background: '#F3E0CE', borderRadius: '50%' }}
                        color={theme.palette.grey[800]}
                    />
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />

            <Popper
                placement="bottom"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                placeholder={undefined}
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions in={open} {...TransitionProps}>
                            <Paper>
                                {open && (
                                    <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                        <Box sx={{ p: 2, pt: 0 }}>
                                            <Divider />

                                            <Divider />
                                            <Typography
                                                variant="h1"
                                                fontSize="16px"
                                                fontWeight="700"
                                                marginTop="25px"
                                                marginLeft="33px"
                                                fontFamily="Plus Jakarta Sans"
                                            >
                                                Welcome Back
                                            </Typography>
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                <ListItemButton
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    selected={selectedIndex === 0}
                                                    onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                        handleListItemClick(event, 0, '/admin/profile')
                                                    }
                                                >
                                                    <ListItemIcon>
                                                        <IconSettings stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                                                </ListItemButton>
                                                {/* <ListItemButton
                                                        sx={{ borderRadius: `${borderRadius}px` }}
                                                        selected={selectedIndex === 0}
                                                        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                                                            handleListItemClick(event, 0, '/admin/change-password')
                                                        }
                                                    >
                                                        <ListItemIcon>
                                                            <KeyIcon sx={{ transform: 'rotate(30deg)' }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="body2">Change Password</Typography>} />
                                                    </ListItemButton> */}

                                                <ListItemButton
                                                    sx={{ borderRadius: `${borderRadius}px` }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    </MainCard>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
