import { gql } from '@apollo/client';

export const LIST_PROMO_CODES = gql`
    query ListPromoCode($input: PromoCodeSearchDTO!) {
        listPromoCode(input: $input) {
            message
            promoCodeList {
                _id
                title
                code
                status
                usage
                metadata {
                    userType
                    discount
                }
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const LIST_OFFERINGS = gql`
    query ListOfferings {
        listOfferings {
            message
            offerings {
                lookup_key
                metadata {
                    discount
                    userType
                }
            }
        }
    }
`;

export const GET_PROMO_CODE_DETAIL = gql`
    query GetPromoCodeDetail($id: String!) {
        getPromoCodeDetail(id: $id) {
            startDate
            endDate
            title
            code
            offering
            metadata {
                discount
            }
        }
    }
`;
