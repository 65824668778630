import { Grid, FormHelperText, MenuItem, Button } from '@mui/material';
import { Label, CustomTextField, CustomGrid } from './add-product.styles';
import { FormikErrors, FormikTouched, getIn } from 'formik';

/* mui icons */
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

/* types */
import { ActiveStatus, FormInputType } from 'types/subscription-products';

type PropTypes = {
    index: number;
    length: number;
    values: FormInputType;
    errors: FormikErrors<FormInputType>;
    touched: FormikTouched<FormInputType>;
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any, Element>) => void;
    handleRemovePrice: (index: number, values: FormInputType) => void;
};

const ErrorMessage = ({ errors, touched, name }) => {
    return <FormHelperText error>{getIn(touched, name) && getIn(errors, name) && getIn(errors, name)}</FormHelperText>;
};

const PricesForm = ({ index, length, values, errors, touched, handleChange, handleBlur, handleRemovePrice }: PropTypes) => {
    return (
        <CustomGrid item>
            <Grid container key={index} spacing={2}>
                <Grid item xs={12} lg={2}>
                    <Label>Price</Label>
                    <CustomTextField
                        placeholder="Enter Price"
                        name={`prices[${index}].price`}
                        value={values.prices[index]?.price ?? 0}
                        size="medium"
                        type="number"
                        onBlur={handleBlur}
                        onChange={(e: any) => e.target.value >= 0 && handleChange(e)}
                        width="100%"
                    />
                    <ErrorMessage {...{ errors, touched, name: `prices[${index}].price` }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Label>Name</Label>
                    <CustomTextField
                        placeholder="Enter Name"
                        name={`prices[${index}].name`}
                        value={values.prices[index]?.name || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        width="100%"
                    />
                    <ErrorMessage {...{ errors, touched, name: `prices[${index}].name` }} />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <Label>Status</Label>
                    <CustomTextField
                        id="price-status"
                        name={`prices[${index}].status`}
                        select
                        value={values.prices[index]?.status || ''}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        width="100%"
                    >
                        {ActiveStatus.map((option, i) => (
                            <MenuItem key={`price-${i}`} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </CustomTextField>
                    <ErrorMessage {...{ errors, touched, name: `prices[${index}].status` }} />
                </Grid>

                <Grid item xs={12} lg={2} sx={{ mt: '40px' }}>
                    <Button onClick={() => handleRemovePrice(index, values)}>
                        <DeleteOutlineOutlinedIcon />
                    </Button>
                </Grid>
            </Grid>
        </CustomGrid>
    );
};

export default PricesForm;
