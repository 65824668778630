// material-ui
import { useMutation } from '@apollo/client';
import { CircularProgress, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

// assets
import { Box } from '@mui/system';
import { SET_NOTIFICATION_READ } from 'grapqhl/reports/mutation';
import { useNavigate } from 'react-router-dom';
import { getNotificationTime } from 'utils/commonHelpers';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notifications, loading, setNotifications, setCount, count, setOpen, refetch }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [setNotificationRead] = useMutation(SET_NOTIFICATION_READ);
    const handleNotificationClick = async (item: any) => {
        if (!item.markAsRead) {
            setCount(count - 1 < 0 ? 0 : count - 1);

            try {
                await setNotificationRead({
                    variables: {
                        input: {
                            reportId: item._id
                        }
                    }
                });

                refetch();
            } catch (error) {
                console.log('Cannot set notification to read.');
            }
        }
        navigate(`reports/view/${item._id}`);
        setOpen(false);
    };
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 350,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((item, index) => {
                return (
                    <ListItemWrapper
                        key={index}
                        sx={{
                            background: item?.markAsRead ? '#ffff' : '#F6F4EF',
                            mb: '3px',
                            borderRadius: '6px',
                            '&:hover': { background: '#E9E1D5' }
                        }}
                        onClick={() => handleNotificationClick(item)}
                    >
                        <ListItem alignItems="center" sx={{ gap: '10px', width: '100%' }}>
                            <Stack rowGap={1} sx={{ width: '100%' }}>
                                <Stack direction="row" spacing={12} justifyContent="space-between">
                                    <Typography variant="h5"> User reported</Typography>
                                    <Typography variant="caption" sx={{ color: '#757575', fontSize: '12px', fontWeight: 500 }}>
                                        {getNotificationTime(item?.createdAt)} ago
                                    </Typography>
                                </Stack>
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: 500,
                                        color: '#2B2929'
                                    }}
                                >
                                    {item?.reportTo} has been reported by {item?.reportBy.firstName} for
                                </Typography>
                                <Typography variant="caption" sx={{ color: '#2B2929', fontSize: '12px', fontWeight: 500 }}>
                                    {item?.title}
                                </Typography>
                            </Stack>
                        </ListItem>
                    </ListItemWrapper>
                );
            })}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: '400'
                }}
            >
                {!notifications?.length && !loading && <p>No Notification Available</p>}

                {loading && <CircularProgress color="secondary" />}
            </Box>
        </List>
    );
};

export default NotificationList;
