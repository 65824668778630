import { gql } from '@apollo/client';

export const CREATE_PAGE = gql`
    mutation ($input: CreatePageDTO!) {
        createPage(input: $input) {
            message
            page {
                _id
                title
                slug
                status
                content
            }
        }
    }
`;

export const UPDATE_PAGE = gql`
    mutation UpdatePage($updatePageId: String!, $input: UpdatePageDTO!) {
        updatePage(id: $updatePageId, input: $input) {
            message
            page {
                _id
                title
                content
                createdAt
            }
        }
    }
`;

// export const DELETE_PAGE = gql`
//     mutation ($id: String!) {
//         deletePage(id: $id) {
//             message
//         }
//     }
// `;

// export const UPLOAD_PAGE_IMAGE = gql`
//     mutation ($input: uploadImageDTO!) {
//         uploadPageImage(input: $input) {
//             url
//         }
//     }
// `;
