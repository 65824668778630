import { HeadCell1 } from 'types';
import * as Yup from 'yup';
import { BillingCycleEnum, FormInputType, ProductStatus } from 'types/subscription-products';

// table header options
export const headCells: HeadCell1[] = [
    {
        id: 'id',
        numeric: true,
        label: 'S.No.',
        align: 'center',
        sort: false
    },
    {
        id: 'name',
        numeric: false,
        label: 'Product Name',
        align: 'center',
        sort: true
    },
    {
        id: 'description',
        numeric: false,
        label: 'Description',
        align: 'center',
        sort: false
    },
    {
        id: 'isActive',
        numeric: false,
        label: 'Status',
        align: 'center',
        sort: false
    },
    {
        id: 'createdAt',
        numeric: false,
        label: 'Created At',
        align: 'center',
        sort: true
    },
    {
        id: 'updatedAt',
        numeric: false,
        label: 'Updated At',
        align: 'center',
        sort: true
    }
];

export const validationSchema = Yup.object().shape({
    productName: Yup.string().min(2).max(80).required('productName is a required'),
    description: Yup.string().min(2).max(500),
    status: Yup.string(),
    billingCycle: Yup.string(),
    prices: Yup.array().of(
        Yup.object().shape({
            price: Yup.number().required('price is a required').min(1, 'price must be at least 1'),
            name: Yup.string(),
            currency: Yup.string(),
            status: Yup.string()
        })
    )
});

export const defaultValue: FormInputType = {
    productName: '',
    description: '',
    status: ProductStatus.Active,
    billingCycle: BillingCycleEnum.MONTHLY,
    prices: [
        {
            price: 0,
            name: '',
            currency: '',
            status: ProductStatus.Active
        }
    ]
};
