// project imports
import { Theme } from '@mui/material/styles';

export default function componentStyleOverrides(theme: Theme, borderRadius: number, outlinedFilled: boolean) {
    const mode = theme.palette.mode;
    const bgColor = mode === 'dark' ? theme.palette.dark[800] : theme.palette.grey[50];
    const menuSelectedBack = mode === 'dark' ? theme.palette.secondary.main + 15 : theme.palette.secondary.light;
    const menuSelected = mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark;

    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    lineHeight: '1.42857',
                    borderRadius: '4px',
                    padding: '11px 15px',
                    fontWeight: '500',
                    boxShadow: 'none',
                    minWidth: '40px',
                    'text-transform': 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
                containedPrimary: {
                    border: `1px solid ${theme.palette.primary.main}`,
                    color: theme.palette.background.paper,
                    '&:hover': {
                        color: theme.palette.primary.main,
                        backgroundColor: 'transparent'
                    }
                },
                outlinedPrimary: {
                    color: theme.palette.primary.main,
                    '&:hover': {
                        color: theme.palette.background.paper,
                        backgroundColor: theme.palette.primary.main
                    }
                },
                sizeLarge: {
                    fontSize: '1rem',
                    lineHeight: '1.25',
                    borderRadius: '8px',
                    padding: '7px',
                    minHeight: '40px'
                },
                containedSizeLarge: {
                    // color: theme.palette.grey[800]
                },
                outlinedSizeLarge: {
                    color: theme.palette.grey[800],
                    '&:hover': {
                        color: theme.palette.background.paper,
                        backgroundColor: theme.palette.primary.main
                    }
                },
                containedSecondary: {
                    border: `1px solid ${theme.palette.secondary.main}`,
                    borderColor: theme.palette.secondary.main,
                    backgroundColor: theme.palette.secondary.main,

                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.secondary.main
                    }
                },
                outlinedSecondary: {
                    borderColor: theme.palette.secondary.main,
                    '&:hover': {
                        color: theme.palette.background.paper,
                        backgroundColor: theme.palette.secondary.main
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    border: `1px solid ${theme.palette.primary.mainFade}`,
                    borderRadius: '4px',
                    padding: '7px',
                    minWidth: '40px',
                    minHeight: '40px',

                    ':hover': {
                        borderColor: `1px solid ${theme.palette.primary.main}`,
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.background.paper,

                        'svg path': {
                            fill: theme.palette.background.paper
                        }
                    },

                    '.MuiTouchRipple-root': {
                        display: 'none'
                    }
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${borderRadius}px`
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    padding: '14px 20px',
                    borderBottom: '2px solid rgba(205, 127, 50, 0.24)'
                },
                title: {
                    fontSize: '1.25rem',
                    lineHeight: '1.4',
                    fontWeight: '700',
                    color: theme.palette.grey[800]
                },
                action: {
                    margin: '0',
                    '.MuiFormControl-root': {
                        '.MuiOutlinedInput-root': {
                            background: 'transparent',
                            paddingLeft: '7px',
                            '&.MuiFoused': {
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: `1px solid ${theme.palette.primary.mainFade}`
                                }
                            },
                            input: {
                                background: 'transparent',
                                borderRadius: '0',
                                color: theme.palette.text.grey3,
                                padding: '10px 14px 10px 0  !important',
                                fontWeight: '400',
                                ':placeholder': {
                                    opacity: 1,
                                    color: theme.palette.text.grey3
                                }
                            },
                            '.MuiSvgIcon-root path': {
                                fill: theme.palette.grey[900]
                            },
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.primary.mainFade,
                                borderRadius: '8px'
                            }
                        }
                    },

                    '.MuiButton-outlinedPrimary': {
                        borderColor: theme.palette.primary.mainFade
                    },

                    '.MuiButtonBase-root': {
                        borderRadius: '8px'
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '16px 20px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center'
                },
                outlined: {
                    border: '1px dashed'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: menuSelected,
                        backgroundColor: menuSelectedBack,
                        '&:hover': {
                            backgroundColor: menuSelectedBack
                        },
                        '& .MuiListItemIcon-root': {
                            color: menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: menuSelectedBack,
                        color: menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.primary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.palette.text.dark
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& > label': {
                        top: 23,
                        left: 0,
                        color: theme.palette.grey[500],
                        letterSpacing: '0.3px',
                        lineHeight: '1.14286',

                        '&[data-shrink="false"]': {
                            top: 9
                        },

                        '&[data-shrink="true"]': {
                            fontSize: '0.875rem'
                        }
                    },
                    '& > div > input': {
                        padding: '30.5px 16px 11.5px !important',
                        fontWeight: '700'
                    },
                    '& legend': {
                        display: 'none'
                    },
                    '& fieldset': {
                        top: 0
                    }
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '.MuiSelect-select': {
                        padding: '30.5px 16px 11.5px !important',
                        fontWeight: '700'
                    },
                    '& > label': {
                        fontWeight: '500'
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.palette.text.dark,
                    '&::placeholder': {
                        color: theme.palette.text.secondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'red'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: outlinedFilled ? bgColor : 'transparent',
                    borderRadius: `${borderRadius}px`,

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: mode === 'dark' ? theme.palette.text.primary + 28 : theme.palette.grey[500]
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.palette.grey[500]
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    },
                    input: {},
                    '.MuiIconButton-root': {
                        border: 'none',
                        // padding: '0',
                        minWidth: '24px',
                        minHeight: '24px',

                        '&:hover': {
                            background: 'transparent',
                            'svg path': {
                                fill: theme.palette.grey[500]
                            }
                        }
                    }
                },
                input: {
                    fontWeight: 500,
                    background: outlinedFilled ? bgColor : 'transparent',
                    padding: '15.5px 14px',
                    borderRadius: `${borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: mode === 'dark' ? theme.palette.text.primary + 50 : theme.palette.grey[300]
                    }
                },
                mark: {
                    backgroundColor: theme.palette.background.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.light
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-tag': {
                        background: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.secondary.light,
                        borderRadius: 4,
                        color: theme.palette.text.dark,
                        '.MuiChip-deleteIcon': {
                            color: mode === 'dark' ? theme.palette.text.primary + 80 : theme.palette.secondary[200]
                        }
                    }
                },
                popper: {
                    borderRadius: `${borderRadius}px`,
                    boxShadow: '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)'
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.palette.primary.mainFade,
                    opacity: mode === 'dark' ? 0.2 : 1
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent'
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.dark,
                    background: mode === 'dark' ? theme.palette.text.primary : theme.palette.primary[200]
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    padding: '5px 8px',
                    minWidth: '73px',
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    color: theme.palette.text.dark,
                    fontSize: '16px'
                }
            }
        },
        MuiTreeItem: {
            styleOverrides: {
                label: {
                    marginTop: 14,
                    marginBottom: 14
                }
            }
        },
        MuiTimelineDot: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiInternalDateTimePickerTabs: {
            styleOverrides: {
                tabs: {
                    backgroundColor: mode === 'dark' ? theme.palette.dark[900] : theme.palette.primary.light,
                    '& .MuiTabs-flexContainer': {
                        borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.primary[200]
                    },
                    '& .MuiTab-root': {
                        color: mode === 'dark' ? theme.palette.text.secondary : theme.palette.grey[900]
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: theme.palette.primary.dark
                    },
                    '& .Mui-selected': {
                        color: theme.palette.primary.dark
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '8px',
                    minHeight: '0',
                    'text-transform': 'initial',
                    '&.Mui-selected': {
                        svg: {
                            'path:not([stroke="none"])': {
                                // fill: theme.palette.secondary.main
                            }
                        },
                        '.MuiTypography-root': {
                            color: theme.palette.secondary.main
                        }
                    },
                    svg: {
                        path: {
                            fill: theme.palette.primary[600]
                        }
                    },
                    '.MuiTypography-root': {
                        color: theme.palette.primary[600]
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: '0'
                },
                flexContainer: {
                    borderBottom: '1px solid',
                    gap: '4px',
                    borderColor: mode === 'dark' ? theme.palette.text.primary + 20 : theme.palette.grey[200]
                },

                indicator: {
                    backgroundColor: theme.palette.secondary.main
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: '12px 0 12px 0'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    // borderColor: mode === 'dark' ? theme.palette.text.primary + 15 : theme.palette.grey[200],
                    borderColor: 'transparent',
                    fontWeight: 500,
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    'text-align': 'left',

                    '&.MuiTableCell-head': {
                        fontSize: '0.75rem',
                        paddingTop: '0',
                        color: theme.palette.grey[500]
                    },
                    '&.MuiTableCell-body': {
                        fontSize: '0.875rem',
                        color: theme.palette.grey[800]
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.palette.background.paper,
                    background: theme.palette.text.primary
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                    padding: 0,
                    color: theme.palette.common.black,
                    fontWeight: '700',
                    // position: 'relative',
                    lineHeight: '1.4'
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    padding: '24px 20px'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    padding: '0 20px',
                    marginBottom: '24px',
                    borderRadius: '0',
                    borderBottom: `1px solid ${theme.palette.primary.mainFade}`,
                    '.MuiInputBase-root': {
                        marginLeft: '0'
                    },
                    '.MuiSelect-icon': {
                        marginTop: '-2px'
                    }
                },
                toolbar: {
                    padding: '0 0 0 20px',
                    color: theme.palette.grey[600]
                },
                displayedRows: {
                    color: theme.palette.grey[600],
                    margin: '10px 0'
                },
                selectLabel: {
                    margin: '10px 0'
                },
                select: {
                    color: theme.palette.grey[600],
                    margin: '0'
                },
                actions: {
                    '.MuiButtonBase-root': {
                        border: 'none'
                    }
                }
            }
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    gap: '24px',
                    '.MuiFormControlLabel-root': {
                        margin: '0'
                    },

                    '.MuiTypography-root': {
                        fontSize: '0.75rem',
                        fontWeight: '500',
                        color: '#5C5959'
                    },

                    '.MuiTouchRipple-root': {
                        display: 'none'
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0',
                    marginRight: '12px',
                    '&.Mui-checked': {
                        svg: {
                            path: {
                                stroke: theme.palette.secondary.main
                            }
                            // rect: {
                            //     '&:first-of-type': {
                            //         fill: 'transparent'
                            //     }
                            // }
                        }
                    },
                    svg: {
                        rect: {
                            stroke: 'rgba(205, 127, 50, 0.24)',
                            '&:first-of-type': {
                                fill: theme.palette.grey[200]
                            }
                        }
                    }
                }
            }
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: '0',
                    marginRight: '12px'
                }
            }
        }
        // MuiList: {
        //     styleOverrides: {
        //         root: {
        //             // fontSize: '1.25rem'
        //             padding: 0
        //         }
        //     }
        // }
    };
}
