import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import {
    FORGOT_PASSWORD,
    LOGIN,
    REGISTER,
    RESET_PASSWORD,
    VERIFY_TOKEN,
    APP_USER_RESET_PASSWORD,
    SEND_RESET_PASSWORD_MAIL,
    GET_ADMIN_LIST,
    GET_ADMIN as ADMIN,
    UPDATE_ADMIN as ADMIN_UPDATE,
    DELETE_ADMIN as ADMIN_DELETE,
    GET_USER_PROFILE,
    UPLOAD_PROFILE_IMAGE as IMAGE_UPLOAD,
    UPLOAD_MENU_IMAGE as IMG_UPLOAD,
    CHANGE_PASSWORD,
    CREATE_PAGE as CREATE_PG,
    UPDATE_PAGE as UPDATE_PM,
    LIST_PAGES as GET_LIST_PAGES,
    CREATE_MENU as CREATE_MENUS,
    LIST_MENUS as GET_LIST_MENUS,
    DELETE_MENU as MENU_DELETE,
    UPDATE_MENU as MENU_UPDATE,
    UPDATE_MENU_STATUS as MENU_STATUS_UPDATE,
    LIST_MENU as GET_LIST_MENU,
    CREATE_TAXONOMY as CREATE_TAXONOMYS,
    UPDATE_TAXONOMY as TAXONOMY_UPDATE,
    DELETE_TAXONOMY as TAXONOMY_DELETE,
    LIST_TAXONOMY as GET_LIST_TAXONOMY,
    LIST_TAXONOMIES as GET_TAXONOMIES_LIST,
    CODE_VERIFICATION,
    RESEND_OTP_CODE,
    VALIDATE_OTP,
    LIST_ALL_PAGES,
    LIST_PAGE,
    GET_SUBSCRIPTION_TRANSACTIONS,
    GET_ALL_EXCLUSIVE_USERS,
    GET_MEDIA,
    GET_MEDIA_UPLOAD_URL,
    SAVE_MEDIA,
    DELETE_MEDIA,
    GET_ALL_MATCH_USERS,
    GET_DASHBOARD_DETAILS,
    CREATE_PROMO_CODE,
    DELETE_PROMO_CODE,
    UPDATE_PROMO_CODE,
    GET_PRE_SIGNED_URL
} from '../grapqhl';
import { PageStatus } from 'views/pageManagement/types';
import { GET_ALL_REPORT_USERS_LIST, GET_REPORT_DETAIL } from 'grapqhl/reports/query';
import { GET_PROMO_CODE_DETAIL, LIST_OFFERINGS, LIST_PROMO_CODES } from 'grapqhl/promoCodes/queries';

type GetReportUsersDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    title?: string[];
    date?: {
        startDate: Date | null;
        endDate: Date | null;
    };
};

type ReportIdInput = {
    reportId?: string;
};

type GetPagesDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    status?: PageStatus | string;
};

type GetMenusDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type GetListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type GetTaxonsDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    taxonomyId: string | undefined;
};

type GetTaxonByTaxonomyIdDTO = {
    taxonomyId: string | undefined;
};

type GetAdminListDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    status?: string;
};

type Pagination = {
    total: number;
    hasNextPage: boolean;
};

type AppUserTransactionDTO = {
    searchText?: string;
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    userId?: string;
    planType?: string;
};

type Transaction = {
    userId: string;
    transactionId: string;
    price: number;
    subscriptionFrequency: string;
    subscriptionStatus?: string;
    userType: string;
    planType: string;
    spot: number;
    createdAt: string;
};

type ListTransactionResponse = {
    message?: string;
    data?: Transaction[];
    pagination?: Pagination;
};

type GetAllExclusiveUsersDTO = {
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
};

type UserIdInputDto = {
    userId?: string;
};

type GetAllMatchUsersDTO = {
    orderBy?: string;
    order?: string;
    limit?: number;
    skip?: number;
    userId?: string;
};

type GetDashboardDetailsDTO = {
    startDate?: any;
    endDate?: any;
};

const useGQL = () => {
    const ADMIN_LOGIN = () => useMutation(LOGIN);
    const ADMIN_REGISTER = () => useMutation(REGISTER);
    const FORGOT_PASS = () => useMutation(FORGOT_PASSWORD);
    const RESET_PASS = () => useMutation(RESET_PASSWORD);
    const TOKEN_VERIFY = () => useMutation(VERIFY_TOKEN);
    const SEND_PASSWORD_RESET_MAIL = () => useMutation(SEND_RESET_PASSWORD_MAIL);
    const APP_USER_RESET_PASS = () => useMutation(APP_USER_RESET_PASSWORD);
    const ADMIN_CHANGE_PASSWORD = () => useMutation(CHANGE_PASSWORD);
    const CODE_VERIFY = () => useMutation(CODE_VERIFICATION);
    const RESEND_CODE = () => useMutation(RESEND_OTP_CODE);

    /* admins */
    const GET_ALL_ADMINS = (input: GetAdminListDTO = {}) => useLazyQuery(GET_ADMIN_LIST, { variables: { input } });
    const GET_ADMIN = (id: String) => useQuery(ADMIN, { variables: { id } });
    const GET_SIGNED_URL = ({ image, skip }) => useQuery(GET_PRE_SIGNED_URL, { variables: { image }, skip });
    const UPDATE_ADMIN = () => useMutation(ADMIN_UPDATE);
    const DELETE_ADMIN = () => useMutation(ADMIN_DELETE);
    const GET_ADMIN_PROFILE = () => useQuery(GET_USER_PROFILE);
    const UPLOAD_PROFILE_IMAGE = () => useMutation(IMAGE_UPLOAD);

    const CREATE_PAGE = () => useMutation(CREATE_PG);
    const UPDATE_PAGE = () => useMutation(UPDATE_PM);
    const LIST_PAGES = (input: GetPagesDTO = {}) => useQuery(GET_LIST_PAGES, { variables: { input }, notifyOnNetworkStatusChange: true });
    const LIST_PAGE_MANAGEMENT = () => useQuery(LIST_ALL_PAGES);
    const LIST_PM = (id: String) => useQuery(LIST_PAGE, { variables: { id } });

    const CREATE_MENU = () => useMutation(CREATE_MENUS);
    const LIST_MENUS = (input: GetMenusDTO = {}) => useQuery(GET_LIST_MENUS, { variables: { input }, notifyOnNetworkStatusChange: true });
    const DELETE_MENU = () => useMutation(MENU_DELETE);
    const LIST_MENU = (id: String | undefined) => useQuery(GET_LIST_MENU, { variables: { id }, fetchPolicy: 'network-only' });
    const UPDATE_MENU = () => useMutation(MENU_UPDATE);
    const UPDATE_MENU_STATUS = () => useMutation(MENU_STATUS_UPDATE);
    const UPLOAD_MENU_IMAGE = () => useMutation(IMG_UPLOAD);

    const CREATE_TAXONOMY = () => useMutation(CREATE_TAXONOMYS);
    const UPDATE_TAXONOMY = () => useMutation(TAXONOMY_UPDATE);
    const DELETE_TAXONOMY = () => useMutation(TAXONOMY_DELETE);
    const LIST_TAXONOMY = (id: String | undefined) => useQuery(GET_LIST_TAXONOMY, { variables: { id }, fetchPolicy: 'network-only' });
    const LIST_TAXONOMIES = (input: GetListDTO = {}) =>
        useQuery(GET_TAXONOMIES_LIST, { variables: { input }, notifyOnNetworkStatusChange: true });
    const VALIDATE_AUTH_OTP = () => useMutation(VALIDATE_OTP);
    const GET_ALL_REPORT_USERS = (input: GetReportUsersDTO = {}) => useLazyQuery(GET_ALL_REPORT_USERS_LIST, { variables: { input } });
    const GET_REPORT_DETAILS = (input: ReportIdInput = {}) => useQuery(GET_REPORT_DETAIL, { variables: { input } });
    const SUBSCRIPTION_TRANSACTION = (input: AppUserTransactionDTO = {}) =>
        useQuery(GET_SUBSCRIPTION_TRANSACTIONS, { variables: { input } });
    const GET_EXCLUSIVE_USERS = (input: GetAllExclusiveUsersDTO = {}) => useQuery(GET_ALL_EXCLUSIVE_USERS, { variables: { input } });
    const GET_MEDIA_BY_USER = (input: UserIdInputDto = {}) => useQuery(GET_MEDIA, { variables: { input } });
    const MEDIA_UPLOAD_URL = () => useMutation(GET_MEDIA_UPLOAD_URL);
    const SAVE_UPLOAD_MEDIA = () => useMutation(SAVE_MEDIA);
    const DELETE_UPLOADED_MEDIA = () => useMutation(DELETE_MEDIA);
    const GET_MATCH_USERS = (input: GetAllMatchUsersDTO = {}) => useQuery(GET_ALL_MATCH_USERS, { variables: { input } });
    const GET_DASHBOARD_DETAIL = (input: GetDashboardDetailsDTO = {}) =>
        useLazyQuery(GET_DASHBOARD_DETAILS, { variables: { input: { startDate: null, endDate: null } } });

    const GET_ALL_PROMO_CODES = () =>
        useQuery(LIST_PROMO_CODES, {
            fetchPolicy: 'network-only',
            variables: { input: { searchText: null, limit: 10, skip: 0 } }
        });
    const GET_ALL_OFFERINGS = () => useQuery(LIST_OFFERINGS, { fetchPolicy: 'network-only' });
    const GET_PROMO_DETAIL = (id: string) => useQuery(GET_PROMO_CODE_DETAIL, { skip: !id, variables: { id }, fetchPolicy: 'network-only' });
    const ADD_PROMO_CODE = () => useMutation(CREATE_PROMO_CODE);
    const EDIT_PROMO_CODE = () => useMutation(UPDATE_PROMO_CODE);
    const DELETE_PROMO = () => useMutation(DELETE_PROMO_CODE);

    return {
        ADMIN_LOGIN,
        ADMIN_REGISTER,
        FORGOT_PASS,
        RESET_PASS,
        TOKEN_VERIFY,
        SEND_PASSWORD_RESET_MAIL,
        APP_USER_RESET_PASS,
        GET_ALL_ADMINS,
        GET_ADMIN,
        UPDATE_ADMIN,
        DELETE_ADMIN,
        GET_ADMIN_PROFILE,
        ADMIN_CHANGE_PASSWORD,
        CREATE_PAGE,
        UPDATE_PAGE,
        CREATE_MENU,
        LIST_MENUS,
        DELETE_MENU,
        LIST_MENU,
        UPDATE_MENU,
        UPDATE_MENU_STATUS,
        UPLOAD_MENU_IMAGE,
        CREATE_TAXONOMY,
        UPDATE_TAXONOMY,
        DELETE_TAXONOMY,
        LIST_TAXONOMY,
        LIST_TAXONOMIES,
        CODE_VERIFY,
        RESEND_CODE,
        UPLOAD_PROFILE_IMAGE,
        VALIDATE_AUTH_OTP,
        LIST_PAGE_MANAGEMENT,
        LIST_PM,
        GET_ALL_REPORT_USERS,
        GET_REPORT_DETAILS,
        SUBSCRIPTION_TRANSACTION,
        GET_EXCLUSIVE_USERS,
        GET_MEDIA_BY_USER,
        MEDIA_UPLOAD_URL,
        SAVE_UPLOAD_MEDIA,
        DELETE_UPLOADED_MEDIA,
        GET_MATCH_USERS,
        GET_DASHBOARD_DETAIL,
        GET_ALL_PROMO_CODES,
        GET_ALL_OFFERINGS,
        ADD_PROMO_CODE,
        DELETE_PROMO,
        GET_PROMO_DETAIL,
        EDIT_PROMO_CODE,
        GET_SIGNED_URL
    };
};

export default useGQL;
