// third-party

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
// type
import { IconPages, IconReport } from 'constants/icons';
import { NavItemType } from 'types';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const settings: NavItemType = {
    id: 'settings',
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'reports',
            title: 'Reports',
            type: 'item',
            icon: IconReport,
            url: '/reports/list',
            breadcrumbs: false
        },
        {
            id: 'page-management',
            title: 'Page Management',
            type: 'item',
            icon: IconPages,
            url: '/page-management/list',
            breadcrumbs: false
        }
    ]
};

export default settings;
