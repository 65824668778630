import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    Link,
    Popper,
    Stack,
    Typography,
    useMediaQuery,
    Badge
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';

// assets
import { useMutation } from '@apollo/client';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { SET_ALL_NOTIFICATION_READ } from 'grapqhl/reports/mutation';
import useGQL from 'hooks/useGQL';
import { ArrangementOrder } from 'types';
import { NotificationListItemProps } from './notification.types';
import { openSnackbar } from 'store/slices/snackbar';
import { useDispatch } from 'react-redux';
// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    const [open, setOpen] = useState(false);
    const { GET_ALL_REPORT_USERS } = useGQL();
    const [refetch, { error, loading, data }] = GET_ALL_REPORT_USERS();
    const [notifications, setNotifications] = useState<NotificationListItemProps[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [order, setOrder] = useState<ArrangementOrder>('desc');
    const [orderBy, setOrderBy] = useState<string>('_id');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [count, setCount] = useState<number>(0);
    const [pageMeta, setPageMeta] = useState<{ limit: number; skip: number }>({ limit: 50, skip: 0 });
    const filter = [];
    const [reason, setReason] = useState<string[]>(filter);
    const [fromDateLocal, setFromDateLocal] = useState<Date | null>(null);

    const [toDateLocal, setToDateLocal] = useState<Date | null>(null);
    const [setAllNotificationRead] = useMutation(SET_ALL_NOTIFICATION_READ);

    useEffect(() => {
        if (data?.getAllReportUsers?.reportUsers) {
            const filteredData = data?.getAllReportUsers?.reportUsers?.filter((user) => !user.markAsRead);
            const countFilteredData = filteredData?.length || 0;
            setNotifications(filteredData);
            setCount(countFilteredData);
        }

        if (error) {
            setNotifications([]);
            dispatch(
                openSnackbar({
                    open: true,
                    message: error.message,
                    severity: 'error'
                })
            );
        }
    }, [data]);

    useEffect(() => {
        const limit = rowsPerPage;
        const skip = page > 0 ? limit * page : 0;
        setPageMeta({ limit, skip });
        refetch({
            variables: {
                input: { limit, skip, order, orderBy, searchText, title: reason, date: { startDate: fromDateLocal, endDate: toDateLocal } }
            }
        });
    }, [rowsPerPage, page]);

    const handleRefetch = (
        values = {
            fromDateLocal,
            toDateLocal
        }
    ) => {
        refetch({
            variables: {
                input: {
                    limit: pageMeta?.limit,
                    skip: pageMeta?.skip,
                    order,
                    orderBy,
                    searchText,
                    title: reason,
                    date: { startDate: values.fromDateLocal, endDate: values.toDateLocal }
                }
            }
        });
    };

    useEffect(() => {
        handleRefetch();
    }, [searchText]);

    const anchorRef = useRef<any>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const handleMarkAsReadAllNotifications = async (item: any) => {
        if (!item.markAsRead) {
            setCount(count - 1 < 0 ? 0 : count);

            try {
                for (const key in item) {
                    await setAllNotificationRead({
                        variables: {
                            input: {
                                notificationIds: item[key]._id
                            }
                        }
                    });
                }

                refetch();
            } catch (ex) {
                console.log('Cannot set all the notification to read.');
            }
        }
        setOpen(false);
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.mainFadeBg,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.text.grey2,
                        overflow: 'initial',
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.primary.mainFade,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.text.grey2
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <Badge badgeContent={count} color="secondary">
                        <NotificationsIcon fontSize="small" />
                    </Badge>
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placeholder={undefined}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            {open && (
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box>
                                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ p: 2 }}>
                                            <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: '500' }}>
                                                All Notification
                                            </Typography>
                                            <Link
                                                sx={{ cursor: 'pointer' }}
                                                onClick={() => handleMarkAsReadAllNotifications(notifications)}
                                            >
                                                Mark all as read
                                            </Link>
                                        </Stack>
                                        <Divider sx={{ my: 0 }} />
                                        <PerfectScrollbar
                                            style={{ height: '100%', maxHeight: '280px', minWidth: '300px' }}
                                            className="vertScroll notificationList"
                                        >
                                            <NotificationList
                                                {...{ notifications, setNotifications, loading, setCount, count, setOpen, refetch }}
                                            />
                                        </PerfectScrollbar>
                                    </Box>
                                </MainCard>
                            )}
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
