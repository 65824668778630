import { gql } from '@apollo/client';

export const LIST_ALL_PAGES = gql`
    query ListPages {
        listPages {
            message
            page {
                _id
                title
                content
                createdAt
            }
        }
    }
`;

export const LIST_PAGE = gql`
    query ListPage($id: String!) {
        listPage(id: $id) {
            message
            page {
                _id
                title
                content
                createdAt
            }
        }
    }
`;

export const LIST_PAGES = gql`
    query ($input: GetPagesDTO!) {
        listPages(input: $input) {
            message
            pages {
                _id
                title
                slug
                status
                content
                createdBy
                updatedAt
                createdAt
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;
