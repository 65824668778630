import React, { useEffect, useState } from 'react';
import { Grid, TextField, Box, Link, Typography, InputLabel } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Spinner from 'components/spinner';
import MainCard from 'ui-component/cards/MainCard';
import CustomEditor from 'components/editors/CustomEditor';
import { EditorProviderWrapper } from 'components/wrapper/editorProviderWrapper';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import useEditorContext from 'hooks/common/useEditorContext';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useGQL from 'hooks/useGQL';
import useSnackbar from 'hooks/common/useSnackbar';

import { EditorSubmit } from 'types/page';
import { Actions } from 'types/editor/draftEditor';
import { CancelButton, SubmitButton } from './page-edit.styles';

const EditPage = () => {
    const navigate = useNavigate();
    const [disableSlug, setDisableSlug] = useState<boolean>(true);
    const {
        state: { rawHTML },
        dispatch: editorDispatch
    } = useEditorContext();

    const { id } = useParams();

    const { handleOpenSnackbar } = useSnackbar();
    const { getConfirmation } = useAlertDialog();

    const { LIST_PM, UPDATE_PAGE } = useGQL();

    const { error: pgError, loading: pgLoading, data: pgData, refetch } = LIST_PM(id!);
    const [handleUpdatePage, { loading, data }] = UPDATE_PAGE();
    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (pgData?.listPage) {
            editorDispatch({ type: Actions.CONVERT_TO_EDITOR_STATE, text: pgData.listPage.page.content });
        }
    }, [pgLoading, pgData]);

    const handleEditorSubmit = async ({ content }: EditorSubmit) => {
        try {
            const isConfirm = await getConfirmation({ title: 'Update Page', message: 'Are you sure you want to update the page?' });
            if (isConfirm) {
                await handleUpdatePage({
                    variables: {
                        updatePageId: id,
                        input: {
                            content: rawHTML
                        }
                    }
                });

                refetch();

                /* meta steps */
                handleOpenSnackbar({ message: 'Page updated successfully. Redirecting...', alertType: 'success' });
                // dispatch(resetEditor());
                setTimeout(() => {
                    navigate('/page-management/list');
                }, 2000);
            }
        } catch (error: any) {
            handleOpenSnackbar({ message: error.message, alertType: 'error' });
        }
    };

    const handleCancel = () => {
        navigate('/page-management/list');
    };

    const breadcrumb = (
        <div>
            <MuiBreadcrumbs aria-label="breadcrumb" separator="›">
                <Link underline="none" color="black" href="/page-management/list">
                    Page Management
                </Link>
                <Typography color="black">{pgData?.listPage?.page?.title}</Typography>
            </MuiBreadcrumbs>
        </div>
    );

    if (pgLoading || !pgData?.listPage) {
        return <Spinner />;
    }

    return (
        <MainCard title={breadcrumb}>
            <Formik
                initialValues={{
                    title: pgData.listPage.page.title,
                    content: pgData.listPage.page.content
                }}
                validationSchema={Yup.object().shape({
                    content: Yup.string()
                })}
                onSubmit={(values, { setSubmitting }) => {
                    handleEditorSubmit(values);
                    setSubmitting(false);
                }}
            >
                {({
                    values,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={10} md={8} lg={5}>
                                <TextField
                                    fullWidth
                                    placeholder="Enter title"
                                    value={values.title}
                                    name="title"
                                    InputLabelProps={{ shrink: true, style: { color: 'black', marginTop: '10px', marginBottom: '5px' } }}
                                    InputProps={{
                                        readOnly: true,
                                        style: { padding: '10px' },
                                        startAdornment: (
                                            <InputLabel
                                                style={{ color: 'black', marginTop: '1rem', paddingBottom: '5px', pointerEvents: 'none' }}
                                            >
                                                Page Title
                                            </InputLabel>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <CustomEditor />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ justifyContent: 'flex-end' }}>
                            <Grid item xs={6} />
                            <Grid item xs={6} container justifyContent="flex-end">
                                <Grid item>
                                    <Box sx={{ marginLeft: 'auto' }}>
                                        <SubmitButton disabled={isSubmitting} variant="contained" type="submit">
                                            Save Changes
                                        </SubmitButton>
                                    </Box>
                                </Grid>

                                <Grid item>
                                    <Box sx={{ marginLeft: '1em' }}>
                                        <CancelButton variant="outlined" onClick={() => navigate(-1)}>
                                            Cancel
                                        </CancelButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </MainCard>
    );
};

export default EditorProviderWrapper(EditPage);
