import { Link as RouterLink } from 'react-router-dom';
import dashLogo from '../../../assets/images/mainLogo.svg';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <img src={dashLogo} alt="HoneyPot" width="124" height="52" />
    </Link>
);

export default LogoSection;
