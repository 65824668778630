import { gql } from '@apollo/client';

export const GET_ALL_REPORT_USERS_LIST = gql`
    query GetAllReportUsers($input: GetReportUsersDTO!) {
        getAllReportUsers(input: $input) {
            message
            reportUsers {
                reportBy {
                    firstName
                    lastName
                }
                createdAt
                _id
                title
                markAsRead
                reportTo
                reporterId
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_REPORT_DETAIL = gql`
    query GetReportUserDetails($input: ReportIdInput!) {
        getReportUserDetails(input: $input) {
            title
            description
            firstName
            userId
            reporterFirstName
            status
            createdAt
            reporterId
        }
    }
`;
