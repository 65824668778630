import { gql } from '@apollo/client';

export const GET_ADMIN_LIST = gql`
    query GetAdminList($input: GetAdminListDTO!) {
        getAdminList(input: $input) {
            message
            adminList {
                _id
                firstName
                lastName
                email
                role
                status
                phone
                profileImage
                profileImageUrl
            }
            pagination {
                total
                hasNextPage
            }
        }
    }
`;

export const GET_ADMIN = gql`
    query ($id: String!) {
        getAdmin(id: $id) {
            message
            admin {
                _id
                firstName
                lastName
                email
                role
                status
                phone
                profileImage
                profileImageUrl
                birthDate
                address
            }
        }
    }
`;

export const GET_PRE_SIGNED_URL = gql`
    query ($image: String!) {
        getPreSignedUrl(image: $image) {
            message
            signedUrl
        }
    }
`;

export const GET_USER_PROFILE = gql`
    query GetUserProfile {
        getUserProfile {
            _id
            firstName
            lastName
            email
            role
            status
            phone
            profileImage
            profileImageUrl
            address
        }
    }
`;
