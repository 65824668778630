import { TextField, Button as MuiButton, Grid as MuiGrid, InputLabel } from '@mui/material';
import styled from 'styled-components';

export const SubmitButton = styled(({ ...otherProps }) => <MuiButton {...otherProps} />)`
    margin-top: 1rem;
`;

export const Button = styled(({ ...otherProps }) => <MuiButton {...otherProps} />)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;

export const CustomGrid = styled(({ ...props }) => <MuiGrid {...props} />)`
    padding: 1rem;
    width: 100%;
`;

export const CustomTextField = styled(({ ...props }) => <TextField {...props} />)`
    width: ${(props) => props.width || '80%'};
    @media (max-width: 520px) {
        width: 100%;
    }
`;

export const Label = styled(({ ...props }) => <InputLabel {...props} />)`
    color: rgba(0, 0, 0, 0.6);
    padding: 0.4em;
`;
