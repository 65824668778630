/* eslint no-underscore-dangle: 0 */
/* eslint no-nested-ternary: 0 */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import date from 'date-and-time';
// material-ui
import { IconButton, Table, TableBody, TableCell, TablePagination, TableRow } from '@mui/material';

// project imports
import Spinner from 'components/spinner';
import FailureLoad from 'components/spinner/fail';

import useTable from 'hooks/common/useTable';
import useGQL from 'hooks/subscriptionProduct/useGQL';
import useSnackbar from 'hooks/common/useSnackbar';
import useAlertDialog from 'hooks/common/useAlertDialog';
import useDebouncedSearch from 'hooks/common/useDebounceSearch';

// types and const
import { Order, Page } from 'types';
import { headCells } from 'constants/subscriptionProducts';
import { SubscriptionProduct } from 'types/subscription-products';

// assets
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CustomMainCard } from './index.styles';

const SubscriptionProductsList = () => {
    const { TableContainer, EnhancedTableHead } = useTable();
    const navigate = useNavigate();
    const { handleOpenSnackbar } = useSnackbar();
    const { getConfirmation } = useAlertDialog();

    const [search, setSearch] = useState<string>('');
    const [rows, setRows] = useState<SubscriptionProduct[]>([]);
    const [orderMeta, setOrderMeta] = useState<Order>({ order: 'asc', orderBy: '_id' });
    const [pageMeta, setPageMeta] = useState<Page>({ page: 0, count: 1, rowsPerPage: 5 });
    const [meta, setMeta] = useState<{ limit: number; skip: number }>({ limit: 5, skip: 0 });

    const { SUBSCRIPTION_PRODUCT_LIST, REMOVE_SUBSCRIPTION_PRODUCT } = useGQL();
    const { error, loading, data, refetch } = SUBSCRIPTION_PRODUCT_LIST({
        searchText: '',
        limit: 5,
        skip: 0,
        order: 'asc',
        orderBy: '_id'
    });
    const [triggerDelete, { data: deleteData }] = REMOVE_SUBSCRIPTION_PRODUCT();

    const [debouncedSearch] = useDebouncedSearch((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event) {
            setSearch(event?.target.value);
        }
    });

    useEffect(() => {
        if (data) {
            setRows(data?.findSubscriptionProducts?.data);
            setPageMeta({ ...pageMeta, count: data.findSubscriptionProducts.pagination.total });
        }
    }, [data]);

    useEffect(() => {
        if (deleteData?.removeSubscriptionProduct.message) {
            handleOpenSnackbar({ message: deleteData?.removeSubscriptionProduct.message, alertType: 'success' });
            handleRefetch();
        }
    }, [deleteData]);

    useEffect(() => {
        /* set page meta */
        const { page, rowsPerPage } = pageMeta;
        const { order, orderBy } = orderMeta;
        const limit = rowsPerPage;
        const skip = page > 0 ? limit * page : 0;
        setMeta({ limit, skip });
        refetch({ input: { searchText: search, limit, skip, order, orderBy } });
    }, [pageMeta.rowsPerPage, pageMeta.page]);

    useEffect(() => {
        /* handle refetch here */
        handleRefetch();
    }, [orderMeta.orderBy, orderMeta.order, search]);

    const handleRefetch = () => {
        refetch({
            input: { searchText: search, limit: meta?.limit, skip: meta?.skip, order: orderMeta.order, orderBy: orderMeta.orderBy }
        });
    };

    const handleRequestSort = (event: React.SyntheticEvent<Element, Event>, property: string) => {
        const isAsc = orderMeta.orderBy === property && orderMeta.order === 'asc';
        setOrderMeta({ ...orderMeta, order: isAsc ? 'desc' : 'asc', orderBy: property });
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPageMeta({ ...pageMeta, page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        event?.target.value && setPageMeta({ ...pageMeta, rowsPerPage: parseInt(event?.target.value, 10) });
    };

    const handleDelete = async (id: string) => {
        const isConfirm = await getConfirmation({
            title: 'Delete Subscription Product',
            message: 'Are you sure you want to delete the subscription product ?'
        });

        if (isConfirm) {
            triggerDelete({ variables: { id } }).catch((err: any) => handleOpenSnackbar({ message: err.message, alertType: 'error' }));
        }
    };

    const handleEdit = (id: string) => {
        navigate(`/subscription-products/edit/${id}`);
    };

    return (
        <CustomMainCard title="Subscription Products List" content={false}>
            <Button component={Link} to="/subscription-products/add" variant="outlined">
                Add Subscription Products
            </Button>
            <>
                {/* table */}
                {loading ? (
                    <Spinner />
                ) : error ? (
                    <FailureLoad />
                ) : (
                    <>
                        <TableContainer>
                            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                                <EnhancedTableHead
                                    headCells={headCells}
                                    order={orderMeta.order}
                                    orderBy={orderMeta.orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            {/* table cells render data */}
                                            <TableCell align="center">{pageMeta.page === 0 ? index + 1 : index + meta.skip + 1}</TableCell>
                                            <TableCell align="center">{row.name}</TableCell>
                                            <TableCell align="center">{row.description}</TableCell>
                                            <TableCell align="center">{row.isActive ? 'active' : 'inactive'}</TableCell>
                                            <TableCell align="center">{date.format(new Date(row.createdAt), 'ddd, MMM DD YYYY')}</TableCell>
                                            <TableCell align="center">{date.format(new Date(row.updatedAt), 'ddd, MMM DD YYYY')}</TableCell>

                                            {/* table cells icon buttons */}
                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                <IconButton onClick={() => handleEdit(row._id)} color="primary" size="large">
                                                    <EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                </IconButton>

                                                <IconButton onClick={() => handleDelete(row._id)} color="error" size="large">
                                                    <DeleteIcon sx={{ fontSize: '1.3rem' }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* table pagination */}
                        <TablePagination
                            rowsPerPageOptions={[1, 5, 10, 15]}
                            component="div"
                            count={pageMeta.count}
                            rowsPerPage={pageMeta.rowsPerPage}
                            page={pageMeta.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
            </>
        </CustomMainCard>
    );
};

export default SubscriptionProductsList;
