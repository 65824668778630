// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics } from '@tabler/icons';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// type
import { NavItemType } from 'types';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics: IconDeviceAnalytics
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const promoCodesManagemant: NavItemType = {
    id: 'promo-code',
    title: <FormattedMessage id="Promo Code Management" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: 'promo-code',
            title: 'Promo Codes',
            type: 'item',
            icon: LocalOfferIcon,
            url: '/promo-code/list',
            breadcrumbs: false
        }
    ]
};

export default promoCodesManagemant;
