import { gql } from '@apollo/client';

export const SET_NOTIFICATION_READ = gql`
    mutation HasReadNotification($input: ReportIdInput!) {
        hasReadNotification(input: $input) {
            message
        }
    }
`;

export const SET_ALL_NOTIFICATION_READ = gql`
    mutation MarkAsReadAllNotification($input: MarkAsReadInput!) {
        markAsReadAllNotification(input: $input) {
            message
        }
    }
`;
